import { Link, StaticQueryDocument, graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import DefaultAppView from '../../app-view/default-app-view';
import { SEO } from '../../components/seo';
import { getTheme } from '../../functions/theme';
import { getUserPreferredLocale, isRunningOnWeb } from '../../functions/utils';
import ReactMarkdown from 'react-markdown'
import { SEOData } from '../../constants/types';
import { useSiteCareerApplicationTitlesI18N_IT } from '../../hooks/site-strapi-i18n-IT/site-career-application-titles-i18n-IT';
import { useSiteCareerApplicationTitlesI18N_EN } from '../../hooks/site-strapi-i18n-EN/site-career-application-titles-i18n-EN';
import { useSiteCareerApplicationDescriptionsI18N_IT } from '../../hooks/site-strapi-i18n-IT/site-career-application-descriptions-i18n-IT';
import { useSiteCareerApplicationDescriptionsI18N_EN } from '../../hooks/site-strapi-i18n-EN/site-career-application-descriptions-i18n-EN';

const CareerDetail = () => {

  const _currentPath: string | undefined = isRunningOnWeb() ? window.location.pathname.split('/').slice(1, 3).pop() : undefined;

  const currentTheme: 'light' | 'dark' = getTheme();
  const _userLocale: string = getUserPreferredLocale();
  let strapiI18NContentTitles: any | undefined;
  let strapiI18NContentDescription: any | undefined;
  let applicationContent: any | undefined;

  if (_userLocale.includes('it') || _userLocale.includes('IT')) {
    strapiI18NContentTitles = useSiteCareerApplicationTitlesI18N_IT();
    strapiI18NContentDescription = useSiteCareerApplicationDescriptionsI18N_IT();
    strapiI18NContentDescription = strapiI18NContentDescription.map((el: any) => el?.node?.localizations?.data[0]?.attributes);
    applicationContent = strapiI18NContentDescription.filter((el: any) => (el?.urlId === _currentPath))[0];
  } else {
    strapiI18NContentTitles = useSiteCareerApplicationTitlesI18N_EN();
    strapiI18NContentDescription = useSiteCareerApplicationDescriptionsI18N_EN();
    applicationContent = strapiI18NContentDescription.map((el: any) => el?.node).filter((el: any) => (el?.urlId === _currentPath))[0];
  }

  return (
    <DefaultAppView currentUrl={`/career-detail/${_currentPath}`} userLocale={_userLocale}>
      <div className={`w-full min-h-screen px-5 pt-20 lg:pt-40 sm:px-10 md:px-16 lg:px-36 ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
        <div className='flex flex-col'>
          <div className='flex-row hidden text-xs uppercase md:text-sm lg:text-lg lg:flex lg:pl-1'>
            <span>{ strapiI18NContentTitles?.singleCareerTitle }</span>
          </div>
          <div className='flex flex-row items-end justify-between text-4xl font-bold pt-7 lg:text-7xl'>
            <span>{ applicationContent?.title }</span>
            <span className='hidden text-sm lg:flex'>{ applicationContent?.pubblicationDate }</span>
          </div>
        </div>
        <div className={`flex flex-col pt-3 pb-8 border-b-[1px] ${currentTheme === 'light' ? 'border-black' : 'border-cardinal-white'}`}>
          <div className='text-xs uppercase md:text-sm lg:text-lg lg:flex'>{ strapiI18NContentTitles?.requirementTitle }</div>
          <div className='flex flex-row pt-3 lg:pt-5'>
            <div className='w-4/5 text-base font-light lg:w-2/3 lg:text-2xl'>
              { applicationContent?.whatSearching }
            </div>
            <div className='items-end justify-end hidden w-1/5 lg:flex lg:items-center lg:w-1/3'>
              <Link to="/careers" className={`py-4 px-2 text-sm font-light lg:font-normal aspect-square flex items-center justify-center lg:text-base lg:px-6 lg:py-8 border-[1px] lg:border-2 rounded-full duration-300 ${currentTheme === 'light' ? 'cardinal-button' : 'cardinal-button-dark'}`}>
                { strapiI18NContentTitles?.back }
              </Link>
            </div>
          </div>
        </div>
        <div className={`flex flex-col w-full lg:w-2/3 pt-8 pb-8 border-b-[1px] ${currentTheme === 'light' ? 'border-black' : 'border-cardinal-white'}`}>
          <div className='flex flex-col pt-4'>
            <div className='text-xs uppercase md:text-sm lg:text-lg lg:flex'>{ strapiI18NContentTitles?.necessaryRequirementsTitle }</div>
            <div className='pt-3 lg:pt-5'>
              <ReactMarkdown children={ applicationContent?.necessaryRequirements } className='text-base font-light lg:text-2xl'></ReactMarkdown>
            </div>
          </div>
          <div className='flex flex-col pt-11 lg:pt-20'>
            <div className='text-xs uppercase md:text-sm lg:text-lg lg:flex'>{ strapiI18NContentTitles?.optionalRequirementsTitle }</div>
            <div className='pt-3 lg:pt-5'>
              <ReactMarkdown children={ applicationContent?.optionalRequirements } className='text-base font-light lg:text-2xl'></ReactMarkdown>
            </div>
          </div>
          <div className='flex flex-col pt-11 lg:pt-20'>
            <div className='text-xs uppercase md:text-sm lg:text-lg lg:flex'>{ strapiI18NContentTitles?.specialRequirementsTitle }</div>
            <div className='pt-3 lg:pt-5'>
              <ReactMarkdown children={ applicationContent?.funnyRequirements } className='text-base font-light lg:text-2xl'></ReactMarkdown>
            </div>
          </div>
          <div className='flex flex-col pt-11 lg:pt-20'>
            <div className='text-xs uppercase md:text-sm lg:text-lg lg:flex'>{ strapiI18NContentTitles?.extraQualificationsTitle }</div>
            <div className='pt-3 lg:pt-5'>
              <ReactMarkdown children={ applicationContent?.languages } className='text-base font-light lg:text-2xl'></ReactMarkdown>
            </div>
          </div>
        </div>
        <div className='py-16 text-base font-light lg:text-2xl'>
          <span>{ strapiI18NContentTitles?.sendMailText }</span> 
          <span className='text-cardinal'>{ strapiI18NContentTitles?.careerMailto }</span>
        </div>
      </div>
    </DefaultAppView>
  )
}

export default CareerDetail;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    allStrapiCareerApplication {
      edges {
        node {
          SEO {
            metaTitle
            metaDescription
            keywords
            preventIndexing
            pathname
          }
        }
      }
    }
  }
`

export const Head = () => {
  const careersSeos: any = seoData && useStaticQuery(seoData).allStrapiCareerApplication.edges;
  const careerTitle: string | undefined = isRunningOnWeb() ? window.location.pathname.split('/').slice(1, 3).pop() : undefined;
  const seo: SEOData = careersSeos.filter((el: any) => el?.node && el.node?.SEO.pathname.includes(careerTitle))[0]?.node.SEO;
  
  return (
    <SEO 
      title={seo?.metaTitle} 
      description={seo?.metaDescription} 
      keywords={seo?.keywords} 
      preventIndexing={seo?.preventIndexing} 
      pathname={seo?.pathname} />   
  )
} 
