import { graphql, useStaticQuery } from "gatsby"

export const useSiteCareerApplicationDescriptionsI18N_EN = () => {
  const data: any = useStaticQuery(graphql`
    query {
      allStrapiCareerApplication {
        edges {
          node {
            urlId
            title
            pubblicationDate
            whatSearching
            necessaryRequirements
            optionalRequirements
            funnyRequirements
            languages
          }
        }
      }
    }
  `)

  return data.allStrapiCareerApplication.edges;

}