import { graphql, useStaticQuery } from "gatsby"

export const useSiteCareerApplicationDescriptionsI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      allStrapiCareerApplication {
        edges {
          node {
            localizations {
              data {
                attributes {
                  urlId
                  title
                  pubblicationDate
                  whatSearching
                  necessaryRequirements
                  optionalRequirements
                  funnyRequirements
                  languages
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiCareerApplication.edges;

}