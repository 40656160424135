import { useStaticQuery, graphql } from "gatsby"

export const useSiteCareerApplicationTitlesI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiCareer {
        localizations {
          data {
            attributes { 
              singleCareerTitle
              requirementTitle
              back
              necessaryRequirementsTitle
              optionalRequirementsTitle
              specialRequirementsTitle
              extraQualificationsTitle
              sendMailText
              careerMailto
            }
          }
        }
      }
    }
  `)

  return data.strapiCareer.localizations.data[0].attributes;

}