import { useStaticQuery, graphql } from "gatsby"

export const useSiteCareerApplicationTitlesI18N_EN = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiCareer {
        singleCareerTitle
        requirementTitle
        back
        necessaryRequirementsTitle
        optionalRequirementsTitle
        specialRequirementsTitle
        extraQualificationsTitle
        sendMailText
        careerMailto
      }
    }
  `)

  return data.strapiCareer;

}